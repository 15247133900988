import queryString from 'query-string';
import {shallowEqual, useSelector} from 'react-redux';
import {getPageData, domain, getHomePageProtocol} from 'selectors';
import {getOFSQuery} from 'misc/helper';
import useSWR from 'swr';
import endpoints from './endpoints';
import {createGetPagesQuery} from 'screens/OptimizeYourPages/saga';

export const usePlan = (uuid) => {
  const {data, error, isLoading} = useSWR(endpoints.plan.get(uuid));
  return {
    data: data?.payload?.plan,
    isLoading,
    isError: error
  };
};

export const useSite = (uuid) => {
  const {data, error, isLoading} = useSWR(endpoints.plan.get(uuid));
  return {
    data: data?.payload?.plan?.site,
    isLoading,
    isError: error
  };
};

export const useTask = (id) => {
  const {data, error, isLoading} = useSWR(endpoints.tasks.get(id));
  return {
    data: data,
    isLoading,
    isError: error
  };
};

// TODO: old alternative to new one at its own file
export const useTasks = () => {
  const {data, error, isLoading} = useSWR(endpoints.tasks.list.get);
  return {
    data: data,
    isLoading,
    isError: error
  };
};

export const useOptimize = (options) => {
  const {data, error, isLoading} = useSWR(
    endpoints.optimize.meta.get(getOFSQuery(options))
  );
  return {
    data: data,
    isLoading,
    isError: error
  };
};

export const useOptimizeMeta = () => {
  const {data, error, isLoading} = useSWR(
    endpoints.optimize.meta.get(createGetPagesQuery(['not-optimized'], '', 1))
  );
  return {
    data: data?.meta,
    isLoading,
    isError: error
  };
};

export const useIsWordPressPlugin = () => {
  const parsedParams = queryString.parse(location.search);
  const {
    selectedProductFeatures,
    currentPlan,
    listProducts,
    defaultProductName
  } = useSelector(
    ({data}) => ({
      selectedProductFeatures:
        data.config &&
        parsedParams?.product &&
        data?.config?.dealer?.products[parsedParams?.product]?.features,
      defaultProductName: data.config && data.config.dealer?.default_product,
      currentPlan: data.plans && data.plans[data.config?.current_plan],
      listProducts: data.config && data.config.dealer?.products
    }),
    shallowEqual
  );
  const currentProductName = currentPlan?.product;
  const currentProductFeatures =
    currentProductName && listProducts[currentProductName]?.features;
  const defaultProductFeatures =
    listProducts && listProducts[defaultProductName]?.features;
  const finalProduct =
    currentProductFeatures || selectedProductFeatures || defaultProductFeatures;
  return finalProduct && finalProduct?.have_plugin === 'wordpress';
};

export const useFullLinkUrl = () => {
  const pageData = useSelector(getPageData);
  const pageDomain = useSelector(domain);
  const pageProtocol = useSelector(getHomePageProtocol);
  const pageUrl = pageData?.page?.url;

  return pageUrl && pageProtocol
    ? `${pageProtocol}://${pageDomain}${pageUrl}`
    : `${pageDomain}${pageUrl}`;
};

export const useCountryFromIp = () => {
  const {data, error, isLoading} = useSWR(endpoints.geo.country.get, (url) =>
    fetch(url).then((res) => res.text())
  );
  return {
    data,
    isLoading,
    isError: error
  };
};

export const useWizardKeywords = () => {
  const {data, error, isLoading} = useSWR(endpoints.keywords.related.get);

  return {
    data,
    isLoading,
    isError: error
  };
};

export const usePages = ({optimized = false}) => {
  const params = new URLSearchParams();
  params.append('page[offset]', 0);
  // params.append('filter[progress]', 'perfect');
  params.append('filter[optimized]', optimized);

  const {data, error, isLoading} = useSWR(
    endpoints.pages.read.get(`?${params.toString()}`)
  );

  return {
    data,
    isLoading,
    isError: error
  };
};

export const useRecommendations = (uuid = null) => {
  const {data, error, isLoading, mutate} = useSWR(
    endpoints.recommendations.all.get({uuid})
  );

  const dismiss = (name) => {
    fetch(endpoints.recommendations.dismiss.get({uuid, name}), {
      method: 'GET'
    }).then((response) => {
      console.log('response = ', response);
      mutate();
    });
  };

  const restart = () => {
    fetch(endpoints.recommendations.restart.get({uuid}), {
      method: 'GET'
    }).then((response) => {
      console.log('response = ', response);
      mutate();
    });
  };

  return {
    data,
    isLoading,
    isError: error,
    actions: {
      dismiss,
      restart
    }
  };
};
