import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {Link, useLocation} from 'react-router-dom';
import {Navigation, Typography} from '@marketgoo/components';
import {List} from '@phosphor-icons/react';
import {getNotifications, getRouteMap} from 'selectors';

const Menu = () => {
  const {t} = useTranslation();
  const location = useLocation();
  const routes = useSelector(getRouteMap);
  const routesWithoutMenu = ['/analyzing'];
  const notifications = useSelector(getNotifications);
  const filterNotificationsMenu =
    notifications.filter((n) => n.type === 'badge') || [];
  const isCurrentRoute = (path) => path === location.pathname;

  const navLinks = [
    {to: routes.dashboard, name: 'dashboard'},
    {to: routes.site_audit, name: 'site_audit'},
    {to: routes.optimize, name: 'optimize'},
    {to: routes.analyze, name: 'analyze'},
    {to: routes.competition, name: 'competition'},
    {to: routes.tools, name: 'tools'}
  ];
  return !routesWithoutMenu.includes(location.pathname) ? (
    <>
      <Navigation.Collapse
        data-cy="nav-header"
        responsive
        closeOnClickChildren
        className="header-menu"
        trigger={
          <Navigation.Button>
            <List size={24} />
          </Navigation.Button>
        }
      >
        {navLinks.map((navLink) => {
          const hasNotification = filterNotificationsMenu?.some(
            (n) => n.name === navLink.name
          );

          return (
            <Navigation.Button
              as={Link}
              to={navLink.to}
              key={navLink.to}
              active={isCurrentRoute(navLink.to)}
            >
              <Typography as="span" bold>
                {t(`menu.${navLink.name}`)}
                {hasNotification && (
                  <span className="notification-badge"></span>
                )}
              </Typography>
            </Navigation.Button>
          );
        })}
      </Navigation.Collapse>
      <Navigation.Divider direction="vertical" />
    </>
  ) : (
    <></>
  );
};

export default Menu;
