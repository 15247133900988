import {createRoot} from 'react-dom/client';
import 'bootstrap';
import * as Sentry from '@sentry/browser';

// Translations
import 'lib/i18next';

import wow from 'wow.js';
new wow({offset: 100}).init();

// TODO: Deprecar esto... Es muy antiguo
import 'app/configure';

import {Provider} from 'react-redux';
import store from 'lib/redux/store';
import connectChannel from 'lib/pushstream-redux';

import config from 'lib/application-config';
if (process.env.NODE_ENV !== 'production') {
  console.log(
    '%c[APP CONFIG]%c También disponible en %cwindow.app_config%c',
    'font-weight: bold',
    'font-weight: normal',
    'font-weight: bold',
    'font-weight: normal'
  );
  console.log((window.app_config = config));
}

// Init pushStream
if (config.user && config.user.ulid) {
  connectChannel(store, config.user.ulid);
}

// Configuración de Sentry incluyendo la versión actual de la aplicación
if (config.tokens.sentry) {
  Sentry.init({
    dsn: config.tokens.sentry,
    release: config.app.version
  });
}

// Llamar a los posibles "postScripts" que hayamos configurado para partners
var customEvents = window.customEvents || {};
(customEvents['load'] || []).forEach(function (_f) {
  _f();
});
(customEvents['size'] || []).forEach(function (_f) {
  window.addEventListener('resize', _f, false);
});

// Make all links rel="external" open in a new page.
$("a[href^='http://']").attr('target', '_blank');
$("a[rel*='external']").attr('target', '_blank');

// Incluir un evento "live" que sirve para todos los clicks en enlaces con
// href externos (empiezan por "http://") estén o no presentes en el DOM
// a la hora de cargar la página inicial
// Añadimos el :not([href^='https://marketplace-vendors.smblogin']) para que no afecte al navbar de vendasta
$('body').delegate(
  "a[href^='http://'], a[href^='https://']:not([href^='https://marketplace-vendors.smblogin'])",
  'click',
  function (e) {
    // NO TENEMOS que prevenir el default, tiene que fluir el bubbling
    // para que se haga realmente el "click"
    // e.preventDefault();

    // Pero le añadimos al elemento el "target=_blank" si no lo tenía
    // NOTA: Aunque se haga varias veces, una por cada click, no importa
    $(e.target).attr('target', '_blank');
  }
);

// Fix para los checkboxes de apertura de competidores. Se usa el plug-in
// "collapse" de bootstrap, pero éste tiene un fallo comentado en los
// siguientes "github issues":
//
// https://github.com/twbs/bootstrap/issues/15932
// https://github.com/twbs/bootstrap/pull/15934
//
// En buena sintonía con el espíritu open source, el autor del issue ha
// sido debidamente ninguneado y básicamente la solución que le dan es:
// "go fuck yourself".
//
$('body').delegate('[data-toggle="collapse"]', 'click', function (e) {
  if ($($(e.target).data('target')).hasClass('collapsing')) {
    return false;
  }
});

// Old school code... :-P
$('.pay-yearly').change(function () {
  var parent = $(this).parents('.panel-heading')[0];
  var monthly = $(parent).find('.monthly');
  var yearly = $(parent).find('.yearly');

  if ($(this).prop('checked')) {
    yearly.removeClass('hidden');
    monthly.addClass('hidden');
  } else {
    yearly.addClass('hidden');
    monthly.removeClass('hidden');
  }
});

// Modals
import ModalRoot from 'components/UI/Modal/Root';
const modalRoot = document.getElementById('modal-root');
if (modalRoot) {
  const root = createRoot(modalRoot);
  root.render(
    <Provider store={store}>
      <ModalRoot />
    </Provider>
  );
}

// RPN Ribbon
import RPNRibbon from 'components/RPNRibbon';
const rpnRibbon = document.getElementById('rpn-ribbon');
if (rpnRibbon) {
  const root = createRoot(rpnRibbon);
  root.render(
    <Provider store={store}>
      <RPNRibbon />
    </Provider>
  );
}

// Header
import Header from 'components/Header';
const header = document.getElementById('header');
if (header) {
  const root = createRoot(header);
  root.render(
    <Provider store={store}>
      <Header />
    </Provider>
  );
}

// Notifications
import Notifications from 'components/UI/Notifications';
const notifications = document.getElementById('notifications');
if (notifications) {
  const root = createRoot(notifications);
  root.render(
    <Provider store={store}>
      <Notifications />
    </Provider>
  );
}

// Signup
import Signup from 'screens/Signup';
const signupForm = document.getElementById('signup-form');
if (signupForm) {
  const root = createRoot(signupForm);
  root.render(
    <Provider store={store}>
      <Signup {...signupForm.dataset} />
    </Provider>
  );
}

// Signup WP Plugin
import SignUpWpPlugin from 'screens/Signup/SignUpWpPlugin';
const signupWpPlugin = document.getElementById('signup-wp-plugin');
if (signupWpPlugin) {
  const root = createRoot(signupWpPlugin);
  root.render(
    <Provider store={store}>
      <SignUpWpPlugin {...signupWpPlugin.dataset} />
    </Provider>
  );
}

// Login
import Login from 'screens/Login';
const login = document.getElementById('login');
if (login) {
  const root = createRoot(login);
  root.render(
    <Provider store={store}>
      <Login />
    </Provider>
  );
}

// Login WP Plugin
import LoginWpPlugin from 'screens/Login/LoginWpPlugin';
const loginWpPlugin = document.getElementById('login-wp-plugin');
if (loginWpPlugin) {
  const root = createRoot(loginWpPlugin);
  root.render(
    <Provider store={store}>
      <LoginWpPlugin {...loginWpPlugin.dataset} />
    </Provider>
  );
}

// Remember
import Remember from 'screens/Remember';
const remember = document.getElementById('remember');
if (remember) {
  const root = createRoot(remember);
  root.render(
    <Provider store={store}>
      <Remember {...remember.dataset} />
    </Provider>
  );
}

// Remember Sent
import RememberSent from 'screens/Remember/Sent';
const rememberSent = document.getElementById('remember_sent');
if (rememberSent) {
  const root = createRoot(rememberSent);
  root.render(
    <Provider store={store}>
      <RememberSent {...rememberSent.dataset} />
    </Provider>
  );
}

// Remember Check
import RememberCheck from 'screens/Remember/Check';
const rememberCheck = document.getElementById('remember_check');
if (rememberCheck) {
  const root = createRoot(rememberCheck);
  root.render(
    <Provider store={store}>
      <RememberCheck {...rememberCheck.dataset} />
    </Provider>
  );
}

// Remember Check
import RememberMessage from 'screens/Remember/Message';
const rememberMessage = document.getElementById('remember_message');
if (rememberMessage) {
  const root = createRoot(rememberMessage);
  root.render(
    <Provider store={store}>
      <RememberMessage {...rememberMessage.dataset} />
    </Provider>
  );
}
